import React from 'react';
import ModalVideo from 'react-modal-video';
import Fade from 'react-reveal/Fade';
import '../css/video-modal.css';

class VideoBanner extends React.Component {
  constructor({ props }) {
    super(props);
    this.state = {
      isOpen: false
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }
  render() {
    return (
      <div className="w-100 vh-100 flex items-center bg-black relative overflow-hidden">
        <video
          className="w-100 h-100 absolute top-0 left-0 obj-cover o-50"
          playsinline="true"
          autoPlay="true"
          loop
          muted
          defer="true"
        >
          <source src={this.props.teaser.url} type="video/mp4" />
        </video>
        <div className="pl4 pl5-l mt5-l z-20 relative">
          <Fade>
            <div className="mw7">
              <h1 className="white f2 f-subheadline-l mw7 g-medium lh-title mb3 mt0 pr4">
                {this.props.headline.text}
              </h1>
              <div className="white lh-copy mw7 pr4 measure-l f5 ">
                {this.props.bannerCaption.text}
              </div>
            </div>
          </Fade>

          {this.props.video.text && (
            <div
              className="link yellow button g-medium ttu f5 tracked flex items-center"
              onClick={this.openModal}
            >
              Watch Video
            </div>
          )}
        </div>

        {this.props.video.text && (
          <ModalVideo
            channel="vimeo"
            isOpen={this.state.isOpen}
            videoId={this.props.video.text}
            onClose={() => this.setState({ isOpen: false })}
            autoplay="1"
            maxwidth="1440"
          />
        )}
      </div>
    );
  }
}
export default VideoBanner;
